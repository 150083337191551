import styled from "styled-components"

import { Wrapper } from "./LayoutElements"
import { A, Caption } from "./TypographyElements"

export const FooterWrapper = styled.footer`
  ${Wrapper}
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-primary-default);
`

export const Copyright = styled(Caption)`
  color: var(--color-on-primary);
`

export const AuthorWrapper = styled(Caption)`
  color: var(--color-on-primary);
`

export const Author = styled(A)`
  font-size: var(--font-size-caption);
  line-height: var(--font-height-caption);
  letter-spacing: var(--font-spacing-normal);
  color: var(--color-on-primary);
`
