import { graphql, useStaticQuery } from "gatsby"

export const useFeaturedImages = () => {
  const data = useStaticQuery(graphql`
    query {
      before01: file(
        sourceInstanceName: { eq: "implementations" }
        relativePath: { eq: "rowerek-wodny/rowerek-wodny-01.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      after01: file(
        sourceInstanceName: { eq: "implementations" }
        relativePath: { eq: "rowerek-wodny/rowerek-wodny-06.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      before02: file(
        sourceInstanceName: { eq: "implementations" }
        relativePath: {
          eq: "lodz-motorowa-ebbtide/lodz-motorowa-ebbtide-01.jpg"
        }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      after02: file(
        sourceInstanceName: { eq: "implementations" }
        relativePath: {
          eq: "lodz-motorowa-ebbtide/lodz-motorowa-ebbtide-02.jpg"
        }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data
}
