import React from "react"

import { AuthorWrapper, Author, Copyright, FooterWrapper } from "../elements"

export const Footer = () => {
  return (
    <FooterWrapper>
      <Copyright>&copy; {new Date().getFullYear()} An-lam</Copyright>
      <AuthorWrapper>
        <Author href="https://gorzelinski.com/pl/" rel="author" target="_blank">
          Mateusz Gorzeliński
        </Author>{" "}
        stworzył tę stronę
      </AuthorWrapper>
    </FooterWrapper>
  )
}
