import styled from "styled-components"

import { media } from "../utils/media"
import { SectionWrapper } from "./LayoutElements"

export const ContactWrapper = styled.section`
  ${SectionWrapper}
  background-color: var(--color-neutral-default);
  grid-template-rows: 1fr auto 1fr;
  grid-template-areas:
    ". . ."
    "info map map"
    ". . .";
  align-items: center;

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(var(--space-xl), 1fr) auto auto minmax(
        var(--space-xl),
        1fr
      );
    grid-template-areas:
      ". ."
      "info info"
      "map map"
      ". .";
  `}
`

export const ContactInfo = styled.div`
  grid-area: info;
`

export const MapWrapper = styled.div`
  grid-area: map;
  height: 50vh;
  background-color: var(--color-neutral-darker);
`
