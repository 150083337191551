import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import { media } from "../utils/media"
import { SectionWrapper } from "./LayoutElements"

export const HeroWrapper = styled(BackgroundImage)`
  ${SectionWrapper}
  min-height: 800px;
  grid-template-rows: auto;
  grid-template-areas: "content content .";
  padding-bottom: var(--space-xxl);
  align-items: end;

  ${media.xlDesktop`
    @media (orientation: landscape) {
      min-height: calc(100vh - var(--space-xxl));
    }
  `}

  ${media.lDesktop`
    grid-template-areas: "content content content";
  `}

  ${media.tablet`
    grid-template-areas: "content content content";
  `}

  ${media.mobile`
    grid-template-areas: "content content";
    padding-bottom: var(--space-s);
    @media (orientation: portrait) {
      min-height: calc(100vh - var(--space-xl));
    }
  `}
`

export const HeroContentWrapper = styled.div`
  grid-area: content;
  display: grid;
`
