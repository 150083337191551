import { Link } from "gatsby"
import styled from "styled-components"

import { media } from "../utils/media"
import { Wrapper } from "./LayoutElements"

export const NavLink = styled(Link)`
  font-family: var(--font-body);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-body);
  line-height: var(--font-height-body);
  letter-spacing: var(--font-spacing-wide);
  text-decoration: underline wavy transparent 2px;
  text-underline-offset: var(--space-xxs);
  padding: var(--space-xs);
  background-color: transparent;
  color: var(--color-primary-default);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--space-l);
  transition: text-decoration var(--duration-normal),
    color var(--duration-normal);
  &:hover {
    cursor: pointer;
    text-decoration: underline wavy var(--color-primary-dark) 2px;
    text-underline-offset: var(--space-xxs);
    color: var(--color-primary-dark);
  }
  &:focus {
    outline: none;
    box-shadow: var(--shadow-outline);
  }

  ${media.tablet`
    margin-right: var(--space-s);
  `}

  ${media.mobile`
    display: none;
  `}
`

export const NavLinksWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const NavWrapper = styled.header`
  ${Wrapper}
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-area: header;
`

export const Logo = styled(Link)`
  font-family: var(--font-heading);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-heading-6);
  line-height: var(--font-height-heading-6);
  letter-spacing: var(--font-spacing-normal);
  margin-right: var(--space-m);
  color: var(--color-on-background);
  text-decoration: none;
  cursor: pointer;
`

export const SvgIcon = styled.img`
  display: block;
  width: 1.5rem;
  height: 1.5rem;
`
