import { useStaticQuery, graphql } from "gatsby"

export const useImplementations = () => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          sourceInstanceName: { eq: "implementations" }
          extension: { eq: "jpg" }
        }
        sort: { fields: name }
      ) {
        group(field: relativeDirectory) {
          edges {
            node {
              childImageSharp {
                id
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }

      details: allFile(
        filter: {
          sourceInstanceName: { eq: "implementations" }
          extension: { eq: "json" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            childImplementationsJson {
              title
              description
            }
          }
        }
      }
    }
  `)

  const images = data.images.group.map(item => item.edges)

  const implementations = data.details.edges.map((item, index) => ({
    id: item.node.id,
    title: item.node.childImplementationsJson.title,
    description: item.node.childImplementationsJson.description,
    images: images[index],
  }))

  return implementations
}
