import React from "react"

import { FacebookLink } from "../components"
import {
  NavWrapper,
  Logo,
  NavLink,
  ButtonSecondary,
  NavLinksWrapper,
} from "../elements"

export const Nav = () => {
  return (
    <NavWrapper>
      <NavLinksWrapper aria-label="Loga">
        <Logo to="/">An-lam</Logo>
        <FacebookLink></FacebookLink>
      </NavLinksWrapper>
      <NavLinksWrapper aria-label="Sekcje">
        <NavLink to="/#oferta">Oferta</NavLink>
        <NavLink to="/realizacje">Realizacje</NavLink>
        <NavLink to="/#o-nas">O nas</NavLink>
        <ButtonSecondary to="#kontakt">Kontakt</ButtonSecondary>
      </NavLinksWrapper>
    </NavWrapper>
  )
}
