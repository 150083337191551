import { graphql, useStaticQuery } from "gatsby"

export const useHeroBackgroundImage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "lodz-tlo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data
}
