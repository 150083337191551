import styled, { css } from "styled-components"

import { media } from "../utils/media"

export const Wrapper = css`
  padding-left: 30%;
  padding-right: 30%;

  ${media.xlDesktop`
    padding-left: 25%;
    padding-right: 25%;
  `}

  ${media.lDesktop`
    padding-left: 18%;
    padding-right: 18%;
  `}

  ${media.desktop`
    padding-left: var(--space-xxl);
    padding-right: var(--space-xxl);
  `}

  ${media.tablet`
    padding-left: var(--space-xl);
    padding-right: var(--space-xl);
  `}

  ${media.mobile`
    padding-left: var(--space-s);
    padding-right: var(--space-s);
  `}
`

export const GridLayout = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--space-l);

  ${media.tablet`
    grid-gap: var(--space-m);
  `}

  ${media.mobile`
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-s);
  `}
`

export const LayoutWrapper = styled.div`
  display: grid;
  grid-template-rows: var(--space-xxl) auto var(--space-xxl);
  grid-template-areas:
    "header"
    "main"
    "footer";

  ${media.mobile`
    grid-template-rows: var(--space-xl) auto var(--space-xl);
  `}
`

export const MainWrapper = styled.main`
  grid-area: main;
`

export const SectionWrapper = css`
  ${Wrapper}
  ${GridLayout}
  height: auto;
`
