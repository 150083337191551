import React from "react"

import GlobalStyles from "../themes/global-styles"
import { LayoutWrapper, MainWrapper } from "../elements"
import { Nav, Footer } from "../components"

export const Layout = ({ children }) => {
  return (
    <LayoutWrapper data-testid="app">
      <GlobalStyles />
      <Nav></Nav>
      <MainWrapper>{children}</MainWrapper>
      <Footer></Footer>
    </LayoutWrapper>
  )
}
