import styled from "styled-components"

import { media } from "../utils/media"
import { SectionWrapper } from "./LayoutElements"

export const AboutUsWrapper = styled.section`
  ${SectionWrapper}
  background-color: var(--color-background-default);
  grid-template-rows: minmax(var(--space-xl), 1fr) auto minmax(
      var(--space-xl),
      1fr
    );
  grid-template-areas:
    ". . ."
    "title image description"
    ". . .";

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    grid-template-rows:
      minmax(var(--space-xl), 1fr) repeat(3, auto)
      minmax(var(--space-xl), 1fr);

    grid-template-areas:
      ". ."
      "title image"
      "description image"
      "description ."
      ". .";
  `}

  ${media.mobile`
    grid-template-areas:
      ". ."
      "title title"
      "image image"
      "description description"
      ". .";
  `}
`

export const AboutUsTitle = styled.div`
  grid-area: title;
`

export const ProfileImage = styled.figure`
  grid-area: image;
`

export const AboutUsDescription = styled.div`
  grid-area: description;
`
