import styled from "styled-components"

import { media } from "../utils/media"
import { GridLayout, SectionWrapper, Wrapper } from "./LayoutElements"

export const ImplementationsWrapper = styled.section`
  ${SectionWrapper}
  background-color: var(--color-neutral-default);
  grid-template-rows: minmax(var(--space-xl), 1fr) auto minmax(
      var(--space-xl),
      1fr
    );
  grid-template-areas:
    ". . ."
    "text images images"
    ". . .";
  align-items: center;

  ${media.tablet`
    grid-template-rows: minmax(var(--space-xl), 1fr) auto auto minmax(
        var(--space-xl),
        1fr
      );
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      ". ."
      "text text"
      "images images"
      ". .";
  `}

  ${media.mobile`
    grid-template-rows: 1fr auto auto 1fr;
  `}
`

export const ImplementationsTextWrapper = styled.div`
  display: grid;
  grid-area: text;
`

export const ImplementationsImagesWrapper = styled.div`
  grid-area: images;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: var(--space-l);
  align-items: start;

  ${media.tablet`
    grid-gap: var(--space-m);
  `}

  ${media.mobile`
    grid-gap: var(--space-s);
  `}
`

export const MoreImplementationsTitle = styled.div`
  ${Wrapper}
  margin-top: var(--space-xxl);

  ${media.mobile`
    margin-top: var(--space-l);
  `}
`

export const MoreImplementationsWrapper = styled.div`
  ${Wrapper}
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: var(--space-xxl);
  background-color: var(--color-neutral-default);
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);

  ${media.mobile`
    padding-top: var(--space-l);
    padding-bottom: var(--space-l);
  `}
`

export const ImplementationDetailsWrapper = styled.article`
  ${GridLayout}
  grid-template-areas: "text images images";
  align-items: start;
  :nth-child(2n) {
    grid-template-areas: "images images text";

    ${media.tablet`
      grid-template-areas:
        "text text text"
        "images images images";
    `}

    ${media.mobile`
      grid-template-areas:
        "text text"
        "images images";
    `}
  }

  ${media.tablet`
    grid-template-areas:
      "text text text"
      "images images images";
  `}

  ${media.mobile`
    grid-template-areas:
      "text text"
      "images images";
  `}
`
