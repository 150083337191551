import React from "react"
import Img from "gatsby-image"

import { useFeaturedImages } from "../hooks"
import {
  ImplementationsWrapper,
  ImplementationsTextWrapper,
  ImplementationsImagesWrapper,
  ButtonPrimary,
  H2,
  P,
  Caption,
} from "../elements"

export const Implementations = () => {
  const { before01, after01, before02, after02 } = useFeaturedImages()

  return (
    <ImplementationsWrapper id="realizacje">
      <ImplementationsTextWrapper>
        <Caption>Realizacje</Caption>
        <H2>Od rowerków wodnych po jachty</H2>
        <P>
          Rozumiemy, że dobrze wykończona łódź potrafi przyciągnąć wzrok i mieć
          pozytywny wpływ na wizerunek właściciela. Wykorzystując nasze
          20-letnie doświadczenie w branży laminarskiej, potrafimy takie
          wykończenie zapewnić.
        </P>
        <ButtonPrimary to="/realizacje">Zobacz realizacje</ButtonPrimary>
      </ImplementationsTextWrapper>
      <ImplementationsImagesWrapper>
        <Img
          fluid={before01.childImageSharp.fluid}
          alt="Rowerek wodny przed naprawą"
        />
        <Img
          fluid={after01.childImageSharp.fluid}
          alt="Rowerek wodny po naprawie"
        />
        <Img
          fluid={before02.childImageSharp.fluid}
          alt="Jacht Ebbtide przed naprawą"
        />
        <Img
          fluid={after02.childImageSharp.fluid}
          alt="Jacht Ebbtide po naprawie"
        />
      </ImplementationsImagesWrapper>
    </ImplementationsWrapper>
  )
}
