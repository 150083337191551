import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import { SvgIcon } from "../elements"

export const FacebookLink = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "facebook.svg" }) {
        publicURL
      }
      site {
        siteMetadata {
          facebookPageURL
        }
      }
    }
  `)

  return (
    <a
      href={data.site.siteMetadata.facebookPageURL}
      target="_blank"
      rel="noreferrer"
    >
      <SvgIcon src={data.logo.publicURL} alt={"Logo Facebooka"}></SvgIcon>
    </a>
  )
}
