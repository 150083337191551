import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import {
  H2,
  P,
  ImplementationDetailsWrapper,
  ImplementationsImagesWrapper,
  ImplementationsTextWrapper,
} from "../elements"

export const ImplementationDetails = ({ title, description, images }) => {
  return (
    <ImplementationDetailsWrapper>
      <ImplementationsTextWrapper>
        <H2>{title}</H2>
        <P>{description}</P>
      </ImplementationsTextWrapper>
      <ImplementationsImagesWrapper>
        {images
          ? images.map((image, index) => (
              <Img
                key={image.node.childImageSharp.id}
                fluid={image.node.childImageSharp.fluid}
                alt={`${title}, zdjęcie nr ${index + 1}`}
              ></Img>
            ))
          : null}
      </ImplementationsImagesWrapper>
    </ImplementationDetailsWrapper>
  )
}

ImplementationDetails.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
}
