import styled, { css } from "styled-components"

import { media } from "../utils/media"

export const Headings = css`
  font-family: var(--font-heading);
  font-weight: var(--font-weight-normal);
  color: var(--color-on-background);
  letter-spacing: var(--font-spacing-normal);
  margin-bottom: 0.5em;
`

export const Body = css`
  font-family: var(--font-body);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-body);
  line-height: var(--font-height-body);
  letter-spacing: var(--font-spacing-normal);
  color: var(--color-on-background);
  margin-bottom: 1em;

  ${media.mobile`
    font-size: var(--font-size-body-2);
    line-height: var(--font-height-body-2);
  `}
`

export const Small = css`
  font-size: var(--font-size-caption);
  line-height: var(--font-height-caption);

  ${media.mobile`
    font-size: var(--font-size-caption);
    line-height: var(--font-height-caption);
  `}
`

export const H1 = styled.h1`
  ${Headings}
  font-size: var(--font-size-heading-1);
  line-height: var(--font-height-heading-1);
  letter-spacing: var(--font-spacing-narrow);

  ${media.tablet`
    font-size: var(--font-size-heading-2);
    line-height: var(--font-height-heading-2);
  `}

  ${media.mobile`
    font-size: var(--font-size-heading-3);
    line-height: var(--font-height-heading-3);
  `}
`

export const H2 = styled.h2`
  ${Headings}
  font-size: var(--font-size-heading-2);
  line-height: var(--font-height-heading-2);

  ${media.tablet`
    font-size: var(--font-size-heading-3);
    line-height: var(--font-height-heading-3);
  `}

  ${media.mobile`
    font-size: var(--font-size-heading-4);
    line-height: var(--font-height-heading-4);
  `}
`

export const H3 = styled.h3`
  ${Headings}
  font-size: var(--font-size-heading-3);
  line-height: var(--font-height-heading-3);

  ${media.tablet`
    font-size: var(--font-size-heading-4);
    line-height: var(--font-height-heading-4);
  `}

  ${media.mobile`
    font-size: var(--font-size-heading-5);
    line-height: var(--font-height-heading-5);
  `}
`

export const H4 = styled.h4`
  ${Headings}
  font-size: var(--font-size-heading-4);
  line-height: var(--font-height-heading-4);

  ${media.tablet`
    font-size: var(--font-size-heading-5);
    line-height: var(--font-height-heading-5);
  `}

  ${media.mobile`
    font-size: var(--font-size-heading-6);
    line-height: var(--font-height-heading-6);
  `}
`

export const H5 = styled.h5`
  ${Headings}
  font-size: var(--font-size-heading-5);
  line-height: var(--font-height-heading-5);

  ${media.tablet`
    font-size: var(--font-size-heading-6);
    line-height: var(--font-height-heading-6);
  `}

  ${media.mobile`
    font-size: var(--font-size-body);
    line-height: var(--font-height-body);
  `}
`

export const H6 = styled.h6`
  ${Headings}
  font-size: var(--font-size-heading-6);
  line-height: var(--font-height-heading-6);

  ${media.tablet`
    font-size: var(--font-size-body);
    line-height: var(--font-height-body);
  `}

  ${media.mobile`
    font-size: var(--font-size-body-2);
    line-height: var(--font-height-body-2);
  `}
`

export const Subheading = styled.p`
  ${Body}
  font-size: var(--font-size-subheading);
  line-height: var(--font-height-subheading);
  color: var(--color-primary-default);

  ${media.tablet`
    font-size: var(--font-size-body);
    line-height: var(--font-height-body);
  `}

  ${media.mobile`
    font-size: var(--font-size-body);
    line-height: var(--font-height-body);
  `}
`

export const P = styled.p`
  ${Body}
  margin-bottom: 1.5em;
`

export const Ul = styled.ul`
  list-style: square inside;
`

export const Li = styled.li`
  ${Body}
  color: var(--color-primary-default);
  margin-bottom: 0.25em;
`

export const A = styled.a`
  ${Body}
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
`

export const Caption = styled.p`
  ${Body}
  ${Small}
  margin-bottom: 0.333333em;
`

export const Figcaption = styled.figcaption`
  ${Body}
  ${Small}
  margin-top: 0.75em;
  margin-bottom: 0.75em;
`

export const Address = styled.address`
  ${Body}
  font-style: normal;
`
