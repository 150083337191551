import styled from "styled-components"

import { media } from "../utils/media"
import { SectionWrapper } from "./LayoutElements"
import { P } from "./TypographyElements"

export const ServicesWrapper = styled.section`
  ${SectionWrapper}
  grid-template-rows: 1fr auto auto 1fr;
  grid-template-areas:
    ". . ."
    "title description description"
    ". . ."
    ". . .";

  ${media.mobile`
    grid-template-rows:
      minmax(var(--space-xl), 1fr) repeat(5, auto)
      minmax(var(--space-xl), 1fr);
    grid-template-areas:
      "empty empty"
      "title title"
      "description description"
      ". ."
      ". ."
      ". ."
      ". .";
  `}
`

export const Empty = styled.div`
  grid-area: empty;
`

export const ServicesTitle = styled.div`
  grid-area: title;
  display: flex;
  flex-direction: column;
`

export const ServicesDescription = styled(P)`
  grid-area: description;
  margin-top: var(--space-m);

  ${media.mobile`
    margin-top: 0;
  `}
`

export const ServicesListWrapper = styled.div`
  grid-row: 3 / 4;

  ${media.mobile`
    grid-column: 1 / 3;
    grid-row: auto;
  `}
`
