import { Link } from "gatsby"
import styled from "styled-components"

export const ButtonPrimary = styled(Link)`
  font-family: var(--font-body);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-body);
  line-height: var(--font-height-body);
  letter-spacing: var(--font-spacing-wide);
  text-decoration: none;
  padding: var(--space-xs);
  background-color: var(--color-primary-default);
  color: var(--color-on-primary);
  box-shadow: var(--shadow-far);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: start;
  transition: background-color var(--duration-normal),
    box-shadow var(--duration-normal);
  &:visited {
    color: var(--color-on-primary);
  }
  &:hover {
    background-color: var(--color-primary-dark);
    box-shadow: var(--shadow-close);
    cursor: pointer;
  }
  &:focus {
    outline: none;
    box-shadow: var(--shadow-outline);
  }
  &:active {
    background-color: var(--color-on-background);
    box-shadow: var(--shadow-close);
  }
`

export const ButtonSecondary = styled(Link)`
  font-family: var(--font-body);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-body);
  line-height: var(--font-height-body);
  letter-spacing: var(--font-spacing-wide);
  text-decoration: none;
  padding: calc(var(--space-xs) - var(--space-xxxs));
  background-color: transparent;
  border: var(--space-xxxs) solid var(--color-primary-default);
  color: var(--color-primary-default);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color var(--duration-normal),
    box-shadow var(--duration-normal);
  &:visited {
    color: var(--color-primary-default);
  }
  &:hover {
    background-color: var(--color-primary-default);
    color: var(--color-on-primary);
    cursor: pointer;
  }
  &:focus {
    outline: none;
    box-shadow: var(--shadow-outline);
  }
  &:active {
    color: var(--color-on-primary);
    background-color: var(--color-primary-dark);
  }
`
