import React from "react"

import {
  ServicesWrapper,
  Empty,
  ServicesTitle,
  ServicesDescription,
  ServicesListWrapper,
  H2,
  H3,
  Ul,
  Li,
  Caption,
} from "../elements"

export const Services = () => {
  return (
    <ServicesWrapper id="oferta">
      <Empty></Empty>
      <ServicesTitle>
        <Caption>Oferta</Caption>
        <H2>Naprawiamy i odnawiamy</H2>
      </ServicesTitle>
      <ServicesDescription>
        Masz dziurę w burcie? Uszkodzoną owiewkę? A może wstydzisz się swojego
        zaniedbanego jachtu? Pomożemy Ci rozwiązać tego typu problemy.
        Naprawiamy i odnawiamy między innymi:
      </ServicesDescription>
      <ServicesListWrapper>
        <H3>Poszycia jednostek pływających</H3>
        <Ul>
          <Li>Jachty</Li>
          <Li>Łodzie</Li>
          <Li>Kajaki</Li>
          <Li>Rowerki wodne</Li>
        </Ul>
      </ServicesListWrapper>
      <ServicesListWrapper>
        <H3>Karoserie aut</H3>
        <Ul>
          <Li>Spoilery</Li>
          <Li>Owiewki</Li>
          <Li>Poszycia kamperów</Li>
          <Li>Poszycia przyczep kempingowych</Li>
        </Ul>
      </ServicesListWrapper>
      <ServicesListWrapper>
        <H3>Wyroby z laminatu</H3>
        <Ul>
          <Li>Formy</Li>
          <Li>Opryskiwacze rolnicze</Li>
          <Li>Inne części z laminatu</Li>
        </Ul>
      </ServicesListWrapper>
    </ServicesWrapper>
  )
}
