import React from "react"

import { useHeroBackgroundImage } from "../hooks"
import {
  H1,
  HeroWrapper,
  HeroContentWrapper,
  Subheading,
  ButtonPrimary,
} from "../elements"

export const Landing = () => {
  const { background } = useHeroBackgroundImage()

  return (
    <HeroWrapper
      fluid={background.childImageSharp.fluid}
      backgroundColor={`var(--color-background-default)`}
      Tag="section"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto 100%",
        backgroundPosition: "33% 100%",
      }}
    >
      <HeroContentWrapper>
        <H1>Posiadasz uszkodzoną łódź?</H1>
        <Subheading>
          Oferujemy posiadaczom uszkodzonych łodzi, jachtów oraz innych wyrobów
          z laminatu, usługi naprawy i renowacji, które przygotują ich jednostki
          pływające na sezon letni, a także przywrócą podniszczonym częściom
          dawny stan.
        </Subheading>
        <ButtonPrimary to="/#oferta">Sprawdź ofertę</ButtonPrimary>
      </HeroContentWrapper>
    </HeroWrapper>
  )
}
