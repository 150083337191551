import { useStaticQuery, graphql } from "gatsby"

export const useProfileImage = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "andrzej-lawniczak.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data
}
