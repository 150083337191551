import Img from "gatsby-image"
import React from "react"

import { useMap } from "../hooks/use-map"
import {
  A,
  Address,
  ContactInfo,
  ContactWrapper,
  H2,
  P,
  Caption,
  MapWrapper,
} from "../elements"

export const Contact = () => {
  const data = useMap()

  return (
    <ContactWrapper id="kontakt">
      <ContactInfo>
        <Caption>Kontakt</Caption>
        <H2>Pogadajmy</H2>
        <P>
          Jeżeli ponownie chcesz zaznać odrobiny relaksu wśród fal - skontaktuj
          się z nami!
        </P>
        <Address>
          <A href="mailto:andrzej@an-lam.pl">andczak41@gmail.com</A>
          <br />
          <A href="tel:+48601649928">601 649 928</A>
          <br />
          Lubstów, Woj. Wielkopolskie
        </Address>
      </ContactInfo>
      <MapWrapper>
        <a
          href="https://goo.gl/maps/ZT1wCGhcPahGJRca6"
          target="_blank"
          rel="noreferrer"
          aria-label="Mapa"
        >
          <Img
            fluid={data.map.childImageSharp.fluid}
            style={{ width: "auto", height: "100%" }}
          ></Img>
        </a>
      </MapWrapper>
    </ContactWrapper>
  )
}
