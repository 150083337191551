import { useStaticQuery, graphql } from "gatsby"

export const useMap = () => {
  const data = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "mapa.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return data
}
