import React from "react"
import Img from "gatsby-image"

import { useProfileImage } from "../hooks"
import {
  Caption,
  Figcaption,
  H2,
  P,
  AboutUsDescription,
  AboutUsTitle,
  AboutUsWrapper,
  ProfileImage,
  A,
} from "../elements"

export const AboutUs = () => {
  const { image } = useProfileImage()

  return (
    <AboutUsWrapper id="o-nas">
      <AboutUsTitle>
        <Caption>O nas</Caption>
        <H2>Aby zapobiec nudnemu latu</H2>
      </AboutUsTitle>
      <ProfileImage>
        <Img fluid={image.childImageSharp.fluid} alt="Andrzej Ławniczak"></Img>
        <Figcaption>Andrzej Ławniczak, założyciel</Figcaption>
      </ProfileImage>
      <AboutUsDescription>
        <P>
          Moje doświadczenie w branży laminarskiej zaczęło się ponad 20 lat
          temu.
        </P>
        <P>
          W latach 90., jako młody chłopak, zacząłem pracę przy budowie
          największego budynku sakralnego w Polsce -{" "}
          <A
            href="https://pl.wikipedia.org/wiki/Bazylika_Naj%C5%9Bwi%C4%99tszej_Maryi_Panny_Liche%C5%84skiej_w_Licheniu_Starym"
            target="_blank"
            rel="noreferrer"
          >
            Bazyliki w Licheniu Starym
          </A>
          . Zajmowałem się tam tworzeniem form i prefabrykatów. Odlane przeze
          mnie stiuki i inne elementy dekoracyjne do dziś zdobią ściany i sufity
          świątyni. Po 10 latach pracy i zakończeniu budowy, podjąłem pracę w
          jednej z większych, polskich firm zajmujących się budową jachtów -
          TTS.
        </P>
        <P>
          <A href="https://yachtech.pl/" target="_blank" rel="noreferrer">
            Technologie Tworzyw Sztucznych
          </A>{" "}
          - wchodzące w skład grupy HanseYachts - były dla mnie przystanią na
          kolejne 10 lat. W czasie tego okresu, pracowałem przy każdym etapie
          konstrukcji i naprawy jachtów - od budowy i przeróbek form, przez
          laminowanie kadłubów i pokładów, po wykańczanie jachtów (finishing).
        </P>
        <P>
          Zdobyte na przestrzeni lat doświadczenie i umiejętności wykorzystuję
          dziś - prowadząc własną działalność. Dbam o to, aby moi klienci nie
          musieli narzekać na nudne lato, przez niegotową łódź w sezonie letnim.
        </P>
      </AboutUsDescription>
    </AboutUsWrapper>
  )
}
